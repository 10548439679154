@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400&display=swap');

body {

    font-family: 'Cairo', sans-serif;

}

.bottomDiv {
    position: fixed;
    bottom: 0;
    width: 100%;

}

.weapperA {
    max-height: 130px;

    display: flex;
    overflow-x: auto;
}

.weapperA::-webkit-scrollbar {
    width: 0;
}

.weapperA .item {
    min-width: 100px;
    height: 110px;

    text-align: center;

    margin-right: 2px;
}

.testimonial-group>.row {
    overflow-x: auto;
    white-space: nowrap;
}

.testimonial-group>.row>.col-sm-2 {
    display: inline-block;
    float: none;
}

.ss::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}



#site-header {
    z-index: 999;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100% !important;
}

#site-header.hide {
    top: 0px;
}


#catDiv.hide {
    display: none;
}

#menuList.hide {
    display: none;
}

.vertical-side {
    height: 245px;
}

.menuList {
    background-color: rgb(222, 218, 137) !important;
    border: 1px solid rgba(0, 0, 0, 0);
    color: rgb(161, 108, 40);
    margin: 10px 5px;
    min-width: 95px;
    padding: 8px;
}

a:link {
    text-decoration: none;
}


a:visited {
    text-decoration: none;
}


a:hover {
    text-decoration: none;
}


a:active {
    text-decoration: none;
}

a {
    color: black;
}





@media (min-width:320px) {
    .pulse2 {
        animation: animate 2s linear infinite;
        border-radius: 50%;

        height: 69px;

    }
}

@media (min-width:481px) {
    .pulse2 {
        animation: animate 2s linear infinite;
        border-radius: 50%;

        height: 90px;

    }
}

@media (min-width:641px) {
    .pulse2 {
        animation: animate 2s linear infinite;
        border-radius: 50%;

        height: 90px;

    }
}

@media (min-width:961px) {
    .pulse2 {
        animation: animate 2s linear infinite;
        border-radius: 50%;

        height: 90px;

    }
}

@media (min-width:1025px) {
    .pulse2 {
        animation: animate 2s linear infinite;
        border-radius: 50%;

        height: 90px;

    }
}

@media (min-width:1281px) {
    /* hi-res laptops and desktops */
}


@keyframes animate {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 64, 0.7), 0 0 0 0 rgba(255, 0, 64, 0.7);
    }

    40% {
        box-shadow: 0 0 0 15px rgba(255, 0, 64, 0), 0 0 0 0 rgba(255, 0, 64, 0.7);
    }

    80% {
        box-shadow: 0 0 0 15px rgba(255, 0, 64, 0), 0 0 0 15px rgba(255, 0, 64, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 15px rgba(255, 0, 64, 0);
    }
}

.first-icon {
    background-image: url(./assets/img/download4.png) !important;
    background-repeat: no-repeat !important;
    background-size: 35px 35px !important;
    background-position: center !important;
    background: #fff;
    filter: drop-shadow(2px 4px 6px black);

}

.errorSvg {
    background-image: url(./assets/img/cooking.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 35px 35px !important;
    background-position: center !important;
}